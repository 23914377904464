export const clientNameFooter = "PORTAL DAS ESTRELAS";
export const titleMain = "Seja bem-vindo!";
export const subtitleMain = "Aqui você irá imergir em autoconhecimento.";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "PORTAL DAS ESTRELAS";
export const nomeFornecedor = "PORTAL DAS ESTRELAS";
export const artigo = "o";
export const artigoCaps = "O";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://instagram.com/portaldasestrelas8?igshid=ZjE2NGZiNDQ= ",
    profile: "@portaldasestrelas8"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send?1=ptBR&phone=555196992773",
    number: "(51) 99699-2773"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://api.whatsapp.com/send?1=ptBR&phone=555196992773",
  },
];

export const linkAppleStore = "https://apps.apple.com/us/app/portal-das-estrelas/id6448869859"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_portal_das_estrelas.twa"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];